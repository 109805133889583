import { FC, useEffect, useState } from 'react'
import { useSafeTranslation } from 'hooks/useSafeTranslation'
import { Box, Container, Heading } from 'atomic'
import * as css from './LanguageRegionPicker.css'
import Flags from 'components/icons/Flags'
import { Country } from 'types/Countries'
import SmoothScrollToId from 'utils/smooth-scroll-to-id'
import { useModalState } from 'context/ModalStateContext'
import { PageAlternateLink } from 'context/LinkContext'
import { useRouter } from 'next/router'
import { useCookieContext } from 'context/CookieProvider'
import { themeVars } from 'atomic/styles/theme.css'
import * as gtag from 'lib/gtag'

const ALL_SUPPORTED_LOCALES = [
  { locale: 'en-gb', nativeLanguageName: 'English', country: 'United Kingdom', countryCode: 'GB' },
  { locale: 'en-us', nativeLanguageName: 'English', country: 'United States', countryCode: 'US' },
  { locale: 'en-au', nativeLanguageName: 'English', country: 'Australia', countryCode: 'AU' },
  { locale: 'en-ca', nativeLanguageName: 'English', country: 'Canada', countryCode: 'CA' },
  { locale: 'da-dk', nativeLanguageName: 'Dansk', country: 'Denmark', countryCode: 'DK' },
  { locale: 'de-de', nativeLanguageName: 'Deutsch', country: 'Germany', countryCode: 'DE' },
  { locale: 'es-es', nativeLanguageName: 'Español', country: 'Spain', countryCode: 'ES' },
  { locale: 'fr-fr', nativeLanguageName: 'Français', country: 'France', countryCode: 'FR' },
  { locale: 'it-it', nativeLanguageName: 'Italiano', country: 'Italy', countryCode: 'IT' },
  { locale: 'nl-nl', nativeLanguageName: 'Nederlands', country: 'Netherlands', countryCode: 'NL' },
  { locale: 'nb-no', nativeLanguageName: 'Norsk', country: 'Norway', countryCode: 'NO' },
  { locale: 'sv-se', nativeLanguageName: 'Svenska', country: 'Sweden', countryCode: 'SE' },
]

interface LanguageItemProps {
  locale: string
}

interface RegionItemProps {
  countryName: string
  countryCode: string
}

type LanguageRegionPickerProps = {
  pageAlternates?: PageAlternateLink[]
}

const LanguageRegionPicker: FC<LanguageRegionPickerProps> = ({ pageAlternates }) => {
  const [countries, setCountries] = useState<Country[] | null>(null)
  const { cookieCountry, setCookieCountry, cookieLocale, setCookieLocale, consentNecessary } = useCookieContext()
  const [selectedCountry, setSelectedCountry] = useState<string | null>(null)
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(null)
  const { t } = useSafeTranslation()
  const [activeTab, setActiveTab] = useState<'language' | 'region'>('language')
  const { showLanguageRegionModal, closeLanguageRegionModal, openedLanguageRegionModal } = useModalState()
  const router = useRouter()

  if (!cookieCountry || !cookieLocale) {
    return null
  }

  const changeLanguageAndCloseModal = (locale: string) => {
    closeLanguageRegionModal(true)

    // get the pageAlternate where hreflang is equal to the new locale
    const pageAlternate = pageAlternates?.find((pageAlternate) => pageAlternate.hreflang.toLowerCase() === locale)

    const href = pageAlternate?.href?.toLowerCase()
    const url = href?.split('//')[1] // Remove protocol
    const slug = url?.split('/').slice(1).join('/') || '/' // Extract path after domain

    // Remove existing locale prefix to avoid duplication
    const cleanedSlug = slug.startsWith(locale) ? slug.replace(locale, '') : slug

    // Ensure proper formatting of the new path
    const newPath =
      locale === 'en-gb'
        ? cleanedSlug === '/'
          ? '/' // No locale for the root path
          : `/${cleanedSlug}` // Don't add locale for non-root paths
        : cleanedSlug === '/'
          ? `/${locale}` // For non-root, add locale to the path
          : `/${locale}${cleanedSlug}` // Add locale for other pages

    router.push(newPath, undefined, { locale: locale ?? router.locale, scroll: false })
  }

  const scrollToItem = () => {
    SmoothScrollToId('selected-region', { scrollMarginTop: '100px', scrollBehavior: 'auto', scrollBlock: 'start' })
  }

  const handleTabChange = (tab: 'language' | 'region') => {
    setActiveTab(tab)
    if (tab === 'region') {
      setTimeout(scrollToItem, 500)
    }
  }

  useEffect(() => {
    async function fetchCountries() {
      try {
        const response = await fetch('/api/countries')
        const data = (await response.json()) as Country[]
        setCountries(data)
        setSelectedCountry(cookieCountry)
        setSelectedLanguage(cookieLocale)
        scrollToItem()
      } catch (error) {}
    }

    fetchCountries()
    setTimeout(scrollToItem, 500)
  }, [])

  useEffect(() => {
    scrollToItem()
  }, [showLanguageRegionModal])

  const LanguageItem: FC<LanguageItemProps> = ({ locale }) => {
    const nativeLanguageName = ALL_SUPPORTED_LOCALES.find((item) => item.locale === locale)?.nativeLanguageName
    const country = ALL_SUPPORTED_LOCALES.find((item) => item.locale === locale)?.country
    const countryCode = ALL_SUPPORTED_LOCALES.find((item) => item.locale === locale)?.countryCode

    const changeLanguageClick = () => {
      if (consentNecessary && locale !== cookieLocale) {
        setCookieLocale(locale)
        setSelectedLanguage(locale)

        switch (openedLanguageRegionModal) {
          case 'footer':
            gtag.logEvent('langpicker_footer', { action: 'change_locale', valueFrom: cookieLocale, valueTo: locale })
            break
          case 'header':
            gtag.logEvent('langpicker_header', { action: 'change_locale', valueFrom: cookieLocale, valueTo: locale })
            break
          default:
            gtag.logEvent('langpicker_unknown', { action: 'change_locale', valueFrom: cookieLocale, valueTo: locale })
            break
        }

        setTimeout(function () {
          changeLanguageAndCloseModal(locale)
        }, 500)
      }
    }

    const selected = locale === selectedLanguage

    return (
      <Box
        className={`${css.selectItemContainer}`}
        style={{ backgroundColor: selected ? themeVars.colors.kantarell2 : '' }}
        id={selected ? 'selected-language' : ''}
        onClick={changeLanguageClick}
      >
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'} flexShrink={0} marginRight="xxxs">
          <Flags countryCode={countryCode} />
        </Box>
        <Box display={'flex'} flexDirection={'column'} flexGrow={1} flexShrink={1}>
          <Box className={css.row} fontWeight="normal">
            {nativeLanguageName}
          </Box>
          <Box className={css.row} fontWeight="light">
            {country}
          </Box>
        </Box>
      </Box>
    )
  }

  const RegionItem: FC<RegionItemProps> = ({ countryName, countryCode }) => {
    const changeRegionClick = () => {
      if (consentNecessary) {
        setCookieCountry(countryCode)
        setSelectedCountry(countryCode)

        switch (openedLanguageRegionModal) {
          case 'footer':
            gtag.logEvent('langpicker_footer', { action: 'change_country', valueFrom: cookieCountry, valueTo: countryCode })
            break
          case 'header':
            gtag.logEvent('langpicker_header', { action: 'change_country', valueFrom: cookieCountry, valueTo: countryCode })
            break
          default:
            gtag.logEvent('langpicker_unknown', { action: 'change_country', valueFrom: cookieCountry, valueTo: countryCode })
            break
        }

        setTimeout(function () {
          closeLanguageRegionModal(true)
        }, 500)
      }
    }

    const selected = countryCode === selectedCountry

    return (
      <Box
        className={`${css.selectItemContainer}`}
        style={{ backgroundColor: selected ? themeVars.colors.kantarell2 : '' }}
        id={selected ? 'selected-region' : ''}
        onClick={changeRegionClick}
      >
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'} flexShrink={0} marginRight="xxxs">
          <Flags countryCode={countryCode} />
        </Box>
        <Box display={'flex'} flexDirection={'column'} flexGrow={1} flexShrink={1} margin="auto">
          <Box className={css.row} fontWeight="normal">
            {countryName}
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <>
      <Container display={{ mobile: 'flex', tablet: 'none' }} gap={'none'} style={{ width: '100%', padding: 0 }}>
        <Box flexGrow={1} flexShrink={1}>
          <Heading as="h2" fontSize="base" textAlign="left" margin="none">
            <button
              className={`${css.tabButton} ${activeTab === 'language' ? css.activeTab : css.notActiveTab}`}
              onClick={() => handleTabChange('language')}
            >
              {t('language_picker_lang_title')}
            </button>
          </Heading>
        </Box>
        <Box flexGrow={1} flexShrink={1}>
          <Heading as="h2" fontSize="base" textAlign="left" margin="none">
            <button
              className={`${css.tabButton} ${activeTab === 'region' ? css.activeTab : css.notActiveTab}`}
              onClick={() => handleTabChange('region')}
            >
              {t('language_picker_region_title')}
            </button>
          </Heading>
        </Box>
      </Container>
      <Container display={'flex'} margin="none" style={{ width: '100%', height: '100%', padding: 0 }}>
        <Box
          display={{ mobile: activeTab === 'language' ? 'block' : 'none', tablet: 'block' }}
          className={`${css.languageContainer}`}
          paddingRight={{ mobile: 'none', tablet: 'm', desktop: 'xl' }}
          id="language-container"
        >
          <Heading display={{ mobile: 'none', tablet: 'block' }} as="h2" color="malm0" fontSize="base" textAlign="left" margin="none">
            {t('language_picker_lang_title')}
          </Heading>
          <Heading as="h3" color="malm0" fontSize="s" fontWeight="normal" textAlign="left" margin="none" marginTop="l">
            {t('language_picker_suggested_lang')}
          </Heading>
          <Box display={'flex'} flexGrow={1} flexShrink={1}>
            <LanguageItem locale="en-gb" />
          </Box>
          <Heading as="h3" color="malm0" fontSize="s" fontWeight="normal" textAlign="left" margin="none" marginTop="l">
            {t('language_picker_select_lang')}
          </Heading>
          <Box marginTop="s" display={'flex'} flexWrap={'wrap'} gap={'s'} justifyContent={'space-between'} style={{ width: '100%' }}>
            {Array.from(ALL_SUPPORTED_LOCALES).map((item, i) => (
              <LanguageItem key={i} locale={item.locale} />
            ))}
          </Box>
        </Box>
        <Box
          display={{ mobile: activeTab === 'region' ? 'block' : 'none', tablet: 'block' }}
          className={`${css.regionContainer}`}
          paddingLeft={{ mobile: 'none', tablet: 'm', desktop: 'xl' }}
          id="region-container"
        >
          <Heading display={{ mobile: 'none', tablet: 'block' }} as="h2" color="malm0" fontSize="base" textAlign="left" margin="none">
            {t('language_picker_region_title')}
          </Heading>
          <Heading as="h3" color="malm0" fontSize="s" fontWeight="normal" textAlign="left" margin="none" marginTop="l">
            {t('language_picker_suggested_region')}
          </Heading>
          <Box display={'flex'} flexGrow={1} flexShrink={1}>
            <RegionItem countryName="United Kingdom" countryCode="GB" />
          </Box>
          <Heading as="h3" color="malm0" fontSize="s" fontWeight="normal" textAlign="left" margin="none" marginTop="l">
            {t('language_picker_select_region')}
          </Heading>
          <Box
            className={css.selectRegionContainer}
            marginTop="s"
            id="select-region-container"
            display={'flex'}
            flexWrap={'wrap'}
            gap={'s'}
            justifyContent={'space-between'}
          >
            {countries?.map((item, i) => <RegionItem key={i} countryName={item.country} countryCode={item.countryCode} />)}
          </Box>
        </Box>
      </Container>
    </>
  )
}

export default LanguageRegionPicker
